import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeM from '../views/ob/mobile.vue'
import UAParser from 'ua-parser-js'
const uaParser = new UAParser()
const deviceInfo = uaParser.getResult()

const isMobileDevice = deviceInfo.device.type === 'mobile'
Vue.use(VueRouter)

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

export const constRoutesPc = [
	{
		path: '/',
		name: 'Home',
		meta: {
			appname: 'OBWhatsapp',
			topLogo: require('@/assets/ob/logo@2x.png'),
			footLogo: require('@/assets/ob/GBWhatsApp.png'),
			showBreadcrumb: true,
		},
		component: () => import('../views/ob/pc.vue'),
	},
	{
		path: '/download',
		name: 'download',
		meta: {
			appname: 'OBWhatsapp',
			topLogo: require('@/assets/ob/logo@2x.png'),
			footLogo: require('@/assets/ob/GBWhatsApp.png'),
			showBreadcrumb: false,
		},
		component: () => import('@/components/download.vue'),
	},
	{
		path: '/transfer',
		name: 'transfer',
		meta: {
			appname: 'OBWhatsapp',
			topLogo: require('@/assets/ob/logo@2x.png'),
			footLogo: require('@/assets/ob/GBWhatsApp.png'),
			showBreadcrumb: false,
		},
		component: () => import('@/views/transfer.vue'),
	},
]

export const constRoutesM = [
	{
		path: '/',
		name: 'Home',
		meta: {
			appname: 'OBWhatsapp',
			topLogo: require('@/assets/ob/logo@2x.png'),
			footLogo: require('@/assets/ob/GBWhatsApp.png'),
			showBreadcrumb: true,
		},
		component: HomeM,
	},
	{
		path: '/download',
		name: 'download',
		meta: {
			appname: 'OBWhatsapp',
			topLogo: require('@/assets/ob/logo@2x.png'),
			footLogo: require('@/assets/ob/GBWhatsApp.png'),
			showBreadcrumb: false,
		},
		component: () => import('@/components/download.vue'),
	},
	{
		path: '/transfer',
		name: 'transfer',
		meta: {
			appname: 'OBWhatsapp',
			topLogo: require('@/assets/ob/logo@2x.png'),
			footLogo: require('@/assets/ob/GBWhatsApp.png'),
			showBreadcrumb: false,
		},
		component: () => import('@/views/transfer.vue'),
	},
]

var routes = []
if (isMobileDevice) {
	routes = constRoutesM
}
if (!isMobileDevice) {
	routes = constRoutesPc
}

export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
