<template>
	<div class="copy-right">
		© 2023 gbwhatsapp.download - All Rights Reserved. All names, logos, images, and brands are property of their respective owners.
	</div>
</template>

<script>
export default {
	name: "Copy",
};
</script>
