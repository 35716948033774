<template>
	<div id="app">
		<TopheadM class="m" v-if="_isMobile()" :class="$route.meta.appname" :appName="$route.meta.appname" :appLogo="$route.meta.topLogo" />
		<Tophead class="pc" v-if="!_isMobile()" :class="$route.meta.appname" :appName="$route.meta.appname" :appLogo="$route.meta.topLogo" />

		<div v-if="$route.meta.showBreadcrumb" class="bread-cast-row">Latest GB & PLUS BLOG > {{ $route.meta.breadcrumbName || $route.meta.appname }}</div>

		<router-view />

		<Footr :app-name="$route.meta.appname" :app-logo="$route.meta.footLogo" />
		<Tags />
		<copy />

		<div class="telegram-row" v-show="$route.name != 'download'">
			<div class="telegram-item" @click="gotoTele()">
				<span class="telegram-num"></span>
			</div>
			<div class="telegram-msg" v-show="showTeleMsg">Join Our Telegram Channel</div>
		</div>

	</div>
</template>


<script>
import "@/css/main.scss";
import "@/css/mobile/common.scss";
import "@/css/pc/common.scss";
import TopheadM from "@/components/mobile/Tophead.vue";
import Tophead from "@/components/pc/Tophead.vue";
import Footr from "@/components/pc/Footr_gb.vue";
import Tags from "@/components/pc/tags.vue";
import copy from "@/components/pc/copyright.vue";
import ClientOnly from "vue-client-only";


export default {
	name: "app",
	components: {
		Tophead,
		TopheadM,
		Footr,
		Tags,
		copy,
		ClientOnly,
	},
	data() {
		return {
			myApp: null,
			screenWidth: null,
			is_mobile: null,
			toplogo: require('@/assets/ob/logo@2x.png'),
			footlogo: require('@/assets/ob/GBWhatsApp.png'),
			showTeleMsg: true,
		};
	},
	computed: {
		deviceType() {
			// 在服务器端渲染期间，从Vue SSR上下文中获取设备类型
			if (this.$ssrContext) {
				return this.$ssrContext.deviceType;
			}

			// 在客户端渲染期间，使用窗口宽度检测设备类型
			const windowWidth =
				window.innerWidth || document.documentElement.clientWidth;
			return windowWidth < 768 ? "mobile" : "desktop";
		},
		isMobile() {
			return this.deviceType === "mobile";
		},
	},
	mounted() {
		this.myApp = this.$store.state.gb;
		this.$logEvent("show_obhome");

		setTimeout(() => {
			this.showTeleMsg = false;
		}, 2000);
	},

	methods: {
		_isMobile() {
			if (typeof navigator !== "undefined") {
				// 在浏览器环境中执行的代码
				// 使用 navigator 对象进行相关操作
				return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
					navigator.userAgent
				);
			} else {
				// 在服务器环境中执行的代码
				// 忽略或提供替代方案
				return false;
			}
		},
		gotoTele() {
			if (typeof window !== "undefined") {
				// 在浏览器环境中执行的代码
				window.location.href = "https://t.me/gb_download";
			}
		},
		gotochat() {
			if (typeof window !== "undefined") {
				// 在浏览器环境中执行的代码
				window.location.href = "https://www.whatsplus.online/";
			}
		},
	},
};
</script>
