<template>
	<div id="download" class="big-download-btn" @click="download()">
		<img src="@/assets/gb/downlaod.png" class="dowanlod-img" alt="dowanlod" />
		<div class="dowanlod-name">
			Download
		</div>
	</div>
</template>
<script>
export default {
	name: "dowanlod",
	props: {
		from: String,
		filename: String,
		isOffical: Number,
		name: String,
	},
	data() {
		return {
			domainMap: {
				domain: "obwhatsappdownload.com",
				appName: "OB_obwhatsappdownload.com",
			},
		};
	},
	methods: {
		download() {
			if (this.name) {
				window.location.href = `/download?apps=${this.name}&from=${this.from}&filename=${this.filename}&isOffical=${this.isOffical}&domain=${this.domainMap.domain}&appName=${this.domainMap.appName}`;
			}

			this.$logEvent(`click_${this.name}home_download`);
		},
	},
};
</script>
