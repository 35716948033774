<template>
	<div id="head" class="head" @mouseleave="leaveNav()">
		<div class="head-logo-row">
			<img :src="appLogo" class="head-img" alt="logo">
			<div class="head-name">
				{{appName}}
			</div>
		</div>
		<!-- <div class="nav-row" id="nav-row">
			<div class="nav-item" :class="{'active':nav == 1}" @mouseover="clickNav(1)">
				Products
			</div>
			<div class="nav-item" :class="{'active':nav == 2}" @mouseover="clickNav(2)">
				Android Apps
			</div>
			<div class="nav-item item-3" :class="{'active':nav == 3}" @mouseover="clickNav(3)">
				Blogs
			</div>
			<div class="nav-item" :class="{'active':nav == 4}" @mouseover="clickNav(4)">
				About
			</div>
		</div> -->

		<transition name="dropdown-fade-show">
			<div v-show="dropDownShow" :class="`drop-${nav}`" @touchmove.prevent class="dropdown drop-item-arrow">
				<div class="drop-item-row" :class="`drop-item-${nav}`">
					<div class="drop-down-item" v-for="(data,i) in dropDownList[`nav_${nav}`]" :key="i" @click="goto(data.url)">
						{{data.name}}
					</div>
				</div>
			</div>
		</transition>

	</div>
</template>


<script>

export default {
	name: "Tophead",
	data() {
		return {
			dropDownShow: false,
			nav: null,
			from: null,
			dropDownList: {
				nav_1: [
					{ name: "GBWhatsApp", url: "/" },
					{ name: "YoWhatsApp", url: "/yowhatsapp.html" },
					{ name: "OBWhatsApp", url: "/obwhatsapp.html" },
					{ name: "WhatsApp Plus", url: "/whatsapp2plus.html" },
				],
				nav_2: [
					{ name: "App", url: "section-3" },
					{ name: "Games", url: "section-3" },
				],
				nav_3: [
					{ name: "Blogs", url: "/blogs" },
				],
				nav_4: [
					{ name: "DMCA", url: "/dmca" },
				],
			},
		};
	},
	props: {
		appName: String,
		appLogo: String,
	},

	// mounted() {
	// 	document.addEventListener("click", (event) => {
	// 		var cDom = document.querySelector("#nav-row");
	// 		var tDom = event.target;
	// 		if (cDom == tDom || cDom.contains(tDom)) {
	// 			// console.log("in");
	// 		} else {
	// 			this.dropDownShow = false;
	// 			this.nav = null;
	// 		}
	// 	});
	// },
	methods: {
		goto(link) {
			if (this.nav != 2) {
				window.location.href = link;
			}
			
			if (this.nav ==2) {
				if (this.$route.name == "download_pc") {
					this.$router.push({
						name: this.$store.state.lastPage,
						params: { scrollTo: link },
					});
					return;
				}
				this.scroll(link);
			}
		},
		toblog(){
			this.nav = 3
			window.location.href = '/blogs';
		},
		scroll(to) {
			console.log(to);
			document.querySelector(`#${to}`).scrollIntoView({
				behavior: "smooth",
			});
		},
		clickNav(nav) {
			console.log(this.$route);

			this.nav = nav;
			if (this.dropDownShow) {
				return;
			}

			this.dropDownShow = !this.dropDownShow;
			if (!this.dropDownShow) {
				this.nav = null;
			}
		},
		leaveNav() {
			this.dropDownShow = false;
			this.nav = null;
		},
	},
};
</script>
