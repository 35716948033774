export default {
	s1: {
		h1: 'YoWhatsApp Download APK (Updated) %1 2023 – Official Latest (Anti-Ban)',
		recommend1: 'Security verified',
		recommend2: 'Official certification',
		adimg_1: require('@/assets/gb/p-1.png'),
		intro_1: 'YoWhatsApp is the modified version of the official WhatsApp. You get many extra features with YoWhatsApp instead of using the official WhatsApp. Features like hiding online status, airplane mode are the core benefits of YoWhatsApp. It has extra privacy available in it, and there is no need to pay anything to use this mod.',
		title_1: 'YoWhatsApp vs WhatsApp – What’s The Difference?',
		intro_2: 'There are many changes in YoWhatsApp instead of WhatsApp. Like good-looking themes availability, hide online status, turn on airplane mode, etc. Below is a list of some of the main key features of YoWhatsApp VS WhatsApp.',
	},
	apkdetail: {
		title_1: 'YoWhatsApp Download APK',
	},
	s2: {
		title_2: 'Summary of YoWhatsApp APK features',
		intro_3: 'YoWhatsApp offers customization features and so much more. Check the list down below, and we’re sure that you’ll be convinced of getting this mod for your device.',
		adimg_1: require('@/assets/yo/ad1.png'),
		ul: [
			{ head: 'Auto Reply', content: 'Firstly, you can also use this auto-reply feature when you want to reply to any of your friends at any time. ', img: require('@/assets/gb/ulcontent-1.png') },
			{ head: 'DND Mod', content: 'If you are using some other app on your Android phone and don’t want to get disturbed by Whatsapp messages then you can use DND feature to disable the internet connection for Yo Whatsapp only.' },
			{ head: 'Text messages Broadcast', content: 'You can send Broadcast text messages to groups which is a great feature.' },
			{ head: ' Filter messages', content: 'The Yo Whatsapp APK is formulated with the Filter Messages feature which provides the user with an option to clear chat which can also filter your messages. ' },
			{ head: ' Anti-revoke message', content: 'Comes with Anti-revoke messages feature. ' },
			{ head: ' Share live locations: ', content: 'Additionally, the user can also share their live locations with friends by using Yo Whatsapp 2023. ', img: require('@/assets/gb/ulcontent-2.png') },
			{ head: ' Outstanding effects: ', content: 'Users can add outstanding and unique effects while sending pictures and videos to their friends and loved ones. ' },
			{ head: 'Revoke multiple messages:', content: 'At one time you can revoke multiples messages. ' },
			{ head: 'Send Maximum Pictures:', content: 'Additionally, as compared to official Whatsapp you can send more than 90 pictures at a time. Also, you can send 50 MB video clip and 100 MB audio clip to your contact. ' },
			{ head: 'Endless Themes: ', content: 'Plus, in this modified version of Whatsapp theme feature is also introduced. So there are so many amazing themes and Emojis that you can apply to your phone according to your mode. ', img: require('@/assets/gb/ulcontent-3.png') },
			{ head: 'Download Statuses:', content: 'Another great feature of this app is that you can download the pictures and videos of statuses uploaded by other contacts. ' },
			{ head: 'Amazing Font: ', content: 'Are you bored from the old font? Then, with this feature, you can choose the font of your choice. Customize your favorite font with this feature. ' },
			{ head: 'Messages History: ', content: 'You can check the history of revoked messages from your contacts and groups. ' },
			{ head: 'Alter contacts:', content: 'Alter the media visibility of a particular contact in your gallery. ' },
			{ head: 'Mark the unread messages:', content: 'From the notification, you can mark the read messages. ' },
			{ head: 'Select all chat: ', content: 'With this app, one can select the all chats at one time from the home screen. ', img: require('@/assets/yo/ulcontent-4.png') },
			{ head: 'Hide your status:', content: 'The voice recording status can be hidden. ' },
			{ head: 'Best Image Quality: ', content: 'With Yo Whatsapp you can send pictures of high resolution. ' },
			{ head: 'Language:', content: 'Another best feature, with the help of this language feature, you can select the language from the default lost. ' },
			{ head: 'Notification: ', content: 'This app also allows you to get the notification when anyone from your contact lists changes their profile picture. ' },
			{ head: 'Pop up Notifications:', content: 'Another incredible feature of Yo Whatsapp APK is that you can hide your pop up notifications of this app from your main screen. ' },
		],

		title_3: 'Installation guide',
		intro_4: 'You can not find the YoWhatsApp MOD version on Google Play, so you need to download it from the site and then install it manually. If you do not know how to install Yo WhatsApp on your Android you may follow this installation guide.',
		intro_5: 'To install an APK file into the operating system of your Android, you may need to turn on the ‘Unknown sources’, located in settings>security>unknown sources. Then just follow these steps with images as shown below.',
		adimg_2: require('@/assets/yo/ad4yo.png'),
		title_4: 'Download All Latest Mod for Android Directly (Officially)',
	},
	s3: {
		title_6: 'More Android Mods',
		title_7: 'Android Games',
		download_btn: 'DOWNLOAD',
		big_btn: 'Load More Android Updates',
	},
	s4: {
		title_5: 'The 4 best tools make WhatsApp more useful & poewrful.',
	},
}
