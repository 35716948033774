export default {
	s1: {
		h1: 'واتساب عمر العنابي OBWhatsApp apk %1 2023 محمي و الاقوى أماناً',
		recommend1: 'أمان',
		recommend2: 'الرسمية',
		adimg_1: require('@/assets/gb/p-1.png'),
		intro_1: 'يعتبر واتساب عمر العنابي OBWhatsapp أفضل تطبيق يوفر لمستخدميه مميزات رائعة و متنوعة ومتطورة عن نسخة الواتساب الأخضر التقليدية وكذلك هو أفضل من كل الإصدارات الأخرى الشبيهة به، وذلك للمستوى المرتفع الذى يقدمه واتساب عمر العنابي لمستخدميه من خصائص فريدة حل لأغلب المشاكل التي كانت و سنوضح لك فى مقالنا اليوم كافة تلك المزايا الحصرية لواتس اب عمر العنابي بالإضافة لشرح كيفية تحميل واتساب عمر العنابي سواء للهاتف الجوال الاندرويد أو لتحميله على الكمبيوتر، كل هذا وأكثر سنوضح لك في مقالنا اليوم.',
		title_1: 'OBWhatsApp vs WhatsApp - ما الفرق؟',
		intro_2: 'هناك العديد من التغييرات في OBWhatsApp بدلاً من WhatsApp. مثل توفر السمات ذات المظهر الجميل ، وإخفاء حالة الاتصال بالإنترنت ، وتشغيل وضع الطائرة ، وما إلى ذلك ، فيما يلي قائمة ببعض الميزات الرئيسية لبرنامج OBWhatsApp VS WhatsApp.',
	},
	apkdetail: {
		title_1: 'OBWhatsApp apk',
	},
	s2: {
		title_2: 'ملخص ميزات OBWhatsApp APK',
		intro_3: 'يوفر OBWhatsApp ميزات التخصيص وأكثر من ذلك بكثير. تحقق من القائمة أدناه ، ونحن على يقين من أنك ستقتنع بالحصول على هذا التعديل لجهازك.',
		adimg_1: require('@/assets/ob/ad1.png'),
		ul: [
			{ head: 'رد آلي', content: 'أولاً ، يمكنك أيضًا استخدام ميزة الرد التلقائي هذه عندما تريد الرد على أي من أصدقائك في أي وقت.', img: require('@/assets/ob/ulcontent-1.png') },
			{ head: 'وزارة الدفاع DND', content: 'إذا كنت تستخدم بعض التطبيقات الأخرى على هاتف Android ولا تريد أن تزعجك رسائل Whatsapp ، فيمكنك استخدام ميزة DND لتعطيل اتصال الإنترنت لـ OBWhatsApp فقط.' },
			{ head: 'بث الرسائل النصية', content: 'يمكنك إرسال رسائل نصية البث إلى المجموعات وهي ميزة رائعة.' },
			{ head: ' تصفية الرسائل', content: 'تمت صياغة OBWhatsApp APK باستخدام ميزة تصفية الرسائل التي توفر للمستخدم خيارًا لمسح الدردشة التي يمكنها أيضًا تصفية رسائلك.' },
			{ head: ' رسالة عدم الإلغاء', content: 'يأتي مع ميزة مكافحة إبطال الرسائل.' },
			{ head: ' مشاركة المواقع الحية:', content: 'بالإضافة إلى ذلك ، يمكن للمستخدم أيضًا مشاركة مواقعه الحية مع الأصدقاء باستخدام OBWhatsApp 2023.', img: require('@/assets/ob/ulcontent-2.png') },
			{ head: ' التأثيرات البارزة:', content: 'يمكن للمستخدمين إضافة تأثيرات مميزة وفريدة من نوعها أثناء إرسال الصور ومقاطع الفيديو إلى أصدقائهم وأحبائهم.' },
			{ head: 'إبطال رسائل متعددة:', content: 'في وقت واحد يمكنك إبطال رسائل متعددة. ' },
			{ head: 'الرموز التعبيرية فعالة', content: 'العديد من الرموز التعبيرية المخصصة الشائعة تفاعلية أيضًا عند إرسالها في محادثات ثنائية - يمكن لأي مستخدم النقر لتشغيل تأثيرات متزامنة بملء الشاشة.<br> جربها بمشاعر مشتركة مثل ❤️👍🙏🎉😘🎂😍', img: require('@/assets/ob/ulcontent-5.png') },
			{ head: 'الناس في مكان قريب', content: "أضفنا الأشخاص القريبون لتبادل معلومات الاتصال وجهًا لوجه. مع هذا التحديث ، يمكن أن يساعدك People Nearby في التعرف على أصدقاء جدد أو ترتيب موعد آخر دقيقة لعيد الحب.", img: require('@/assets/ob/ulcontent-6.png') },
			{ head: 'إرسال الحد الأقصى من الصور:', content: 'بالإضافة إلى ذلك ، بالمقارنة مع Whatsapp الرسمي ، يمكنك إرسال أكثر من 90 صورة في المرة الواحدة. يمكنك أيضًا إرسال مقطع فيديو بحجم 50 ميجا بايت ومقطع صوتي بحجم 100 ميجا بايت إلى جهة الاتصال الخاصة بك.' },
			{ head: 'مواضيع لا نهاية لها: ', content: 'بالإضافة إلى ذلك ، تم تقديم ميزة سمة Whatsapp في هذا الإصدار المعدل أيضًا. لذلك هناك العديد من السمات الرائعة والرموز التعبيرية التي يمكنك تطبيقها على هاتفك وفقًا لوضعك.', img: require('@/assets/ob/ulcontent-3.png') },
			{ head: 'تنزيل الحالات:', content: 'ميزة أخرى رائعة لهذا التطبيق هي أنه يمكنك تنزيل الصور ومقاطع الفيديو للحالات التي تم تحميلها من قبل جهات اتصال أخرى.' },
			{ head: 'خط مذهل:', content: 'هل مللت من الخط القديم؟ بعد ذلك ، باستخدام هذه الميزة ، يمكنك اختيار الخط الذي تختاره. تخصيص الخط المفضل لديك مع هذه الميزة.' },
			{ head: 'محفوظات الرسائل: ', content: ' يمكنك التحقق من محفوظات الرسائل الملغاة من جهات الاتصال والمجموعات الخاصة بك.' },
			{ head: 'تعديل جهات الاتصال:', content: 'قم بتغيير الرؤية الإعلامية لجهة اتصال معينة في معرض الصور الخاص بك.' },
			{ head: 'قم بتمييز الرسائل غير المقروءة:', content: 'من الإشعار ، يمكنك وضع علامة على الرسائل المقروءة.' },
			{ head: 'حدد كل الدردشة:', content: ' باستخدام هذا التطبيق ، يمكن للمرء تحديد جميع الدردشات في وقت واحد من الشاشة الرئيسية.', img: require('@/assets/ob/ulcontent-4.png') },
			{ head: 'إخفاء حالتك:', content: 'يمكن إخفاء حالة التسجيل الصوتي.' },
			{ head: 'أفضل جودة للصورة:', content: 'مع OBWhatsApp يمكنك إرسال صور عالية الدقة.' },
			{ head: 'لغة:', content: 'أفضل ميزة أخرى ، بمساعدة ميزة اللغة هذه ، يمكنك تحديد اللغة من اللغة الافتراضية المفقودة.' },
			{ head: 'إشعار:', content: 'يسمح لك هذا التطبيق أيضًا بالحصول على الإشعار عندما يغير أي شخص من قوائم جهات الاتصال الخاصة بك صورة ملفه الشخصي.' },
			{ head: 'إخطارات منبثقة:', content: 'ميزة أخرى لا تصدق في OBWhatsApp APK هي أنه يمكنك إخفاء الإشعارات المنبثقة لهذا التطبيق من شاشتك الرئيسية.' },
		],

		title_3: 'كيفية تثبيت تطبيق واتس اب عمر العنابي',
		intro_4: ' التي قمنا بعرضها وتوضيحها سالفا. <br>نقوم بعمل نسخة احتياطية من المحادثات تحسبا لفقدان النسخة الأصلية خلال عملية التحميل والتثبيت; ويتم ذلك عن طريق الذهاب إلى الإعدادات ثم نقوم بالدخول علي اختيار الدردشات. <br>و كذألك نقوم بعمل نسخة احتياطية وتعتبر هذه الخطوة هي الخطوة الثانية من خطوات تثبيت واتساب obwhatsapp.<br>	وتعتبر الخطوة الثالثة من خطوات تثبيت تنزيل واتس اب عمر العنابي هي مسح أو إلغاء تثبيت تطبيق واتساب من الهاتف الجوال وتتم هذه الخطوة من خلال الذهاب إلى الإعدادات الخاصة بالجهاز الجوال.<br>ثم نذهب إلى خيار التطبيقات ونقوم بالضغط على خيار الواتساب ثم نقوم بعمل إلغاء تثبيت للتطبيق.',
		intro_5: 'ومن ثم نقوم بالخطوة الرابعة وهي خطوة تثبيت تطبيق تنزيل واتساب بلس; على الجهاز الجوال وذلك بعد الضغط علي الأمان ثم اختيار مصادر غير معروفة. <br> أما عن الخطوة الخامسة فهي عبارة عن الدخول إلي التطبيق من سطح المكتب الخاص بالجهاز الجوال ثم نقوم بالضغط على الخيار موافق ونسمح بالأذونات التي يطلبها التطبيق بدون قلق.',
		adimg_2: require('@/assets/gb/ad4ob.webp'),
		title_4: 'Download All Latest Mod for Android Directly (Officially)',
	},
	s3: {
		title_6: 'More Android Mods',
		title_7: 'Android Games',
		download_btn: 'DOWNLOAD',
		big_btn: 'Load More Android Updates',
	},
	s4: {
		title_5: 'The 4 best tools make WhatsApp more useful & poewrful.',
	},
}
