<template>
	<div class="mods-list">
		<h2 class="intro-title intro-title-5" :id="`pos-${mods.type}`" v-html="mods.frame"></h2>
		<div class="mods-row" :class="{'single':adList.length%2==0}" v-if="mods.type== '32'">
			<div class="mods-item has-exp" :exp-data="`show_${name}home_recommend_${data.content_id}`" v-for="(data,i) in adList" :key="i">
				<img :src="data.upload_icon" alt="">
				<div class="mods-title">
					{{data.title}}
				</div>
				<div class="download-btn" @click="goto(data.sub_type,data)" v-html="data.button_txt"></div>
			</div>
		</div>

		<div class="big-ad-row" v-if="mods.type== '33'">
			<div class="big-ad has-exp" :exp-data="`show_${name}home_recommend_${data.content_id}`" :style="{backgroundImage:`url(${data.upload_icon})`,backgroundSize:'100%'}" v-for="(data,i) in adList" :key="i">
				<div class="big-ad-info-row">
					<div class="big-ad-info">
						<div class="big-ad-title">
							{{data.title}}
						</div>
						<div class="big-ad-des">
							{{data.description}}
						</div>
					</div>
					<div class="download-btn" @click="goto(data.sub_type,data)">
						{{data.button_txt}}
					</div>
				</div>
			</div>
		</div>

		<div class="tuimod" v-if="mods.type== '31'">
			<div class="tuimod-item has-exp" :exp-data="`show_${name}home_recommend_${data.content_id}`" v-for="(data,i) in adList" :key="i">
				<div class="ad-img" :style="{backgroundImage:`url(${data.upload_icon})`,backgroundSize:'100%'}"></div>
				<div class="tuimod-row">
					<div class="tuimod-info">
						<div class="tuimod-title">
							{{data.title}}
						</div>
						<div class="tuimod-des">
							{{data.description}}
						</div>
					</div>
					<div class="tuimod-download-btn" @click="goto(data.sub_type,data)">
						{{data.button_txt}}
					</div>
				</div>
			</div>
		</div>

		<div class="load-more-btn" @click="getmore()" v-show="!nomoreAD">
			{{doc.s3.big_btn}} <img src="@/assets/gb/down-arrow.png" alt="">
		</div>

	</div>
</template>

<script>
export default {
	name: "mods",
	props: {
		doc: Object,
		mods: Object,
		name: {
			type: String,
			default: "gb",
		},
	},
	data() {
		return {
			nomoreAD: false,
			adORIList: null,
			adList: null,
		};
	},
	created() {
		this.adORIList = this.mods.contents;
		// console.log(this.mods);
		if (this.adORIList.length <= 6) {
			this.nomoreAD = true;
		}
		this.adList = this.adORIList.splice(0, 6);

		// console.log(this.adList);
	},
	methods: {
		goto(type, obj) {
			console.log(`click_${this.name}home_recommend_${obj.content_id}`);
			this.$logEvent(`click_${this.name}home_recommend_${obj.content_id}`);

			if (type == 2) {
				window.location.href = obj.forward_url;
			}
			if (type == 1) {
				this.downloadApp(obj.download_url);
			}
		},
		downloadApp(apk) {
			this.$global.download(apk);
			this.$logEvent(`download_ad_${apk}`);
		},
		getmore(more) {
			let moremod = this.adORIList.splice(0, 6);
			this.adList = this.adList.concat(moremod);
			if (this.adORIList.length < 1) {
				this.nomoreAD = true;
			}
		},
	},
};
</script>
