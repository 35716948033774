import doc from '@/documents/yo.js' //文案

const state = {
	name: 'YoWhatsApp',
	apk: null,
	logo: require('@/assets/yo/logo@2x.png'),
	footLogo: require('@/assets/yo/footLogo.png'),
	doc: doc,
	// count: 0,
	clicks: 0,
	rate: '4.8',
	rateCount: 8864,
	updateTime: 'Recently'
}

const mutations = {
	UPDATE_APK(state, APK) {
		// 将A组件的数据存放于state
		state.apk = APK
	},
	UPDATE_RATE(state, RATE) {
		// 将A组件的数据存放于state
		state.rate = RATE
	},
	UPDATE_CLICKS(state, CLICKS) {
		// 将A组件的数据存放于state
		state.clicks = CLICKS
	},
	UPDATE_UPDATETIME(state, UPDATETIME) {
		// 将A组件的数据存放于state
		state.updateTime = UPDATETIME
	},
}
const actions = {
	// increaseAction(context) {
	// 	setTimeout(function() {
	// 		//action通过提交mutation改变共享数据状态
	// 		context.commit('increment')
	//     console.log('done');
	// 	}, 3000)
	// },
}
const getters = {
	// doubleCounter(state) {
	// 	return state.count * state.count
	// },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
