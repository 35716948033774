<template>
	<div class="home  home_mobile">

		<section id="section-1" class="section-content">
			<div class="secion-logo">
				<img :src="myApp.logo" class="section-logo-img" alt="logo" />
			</div>

			<h1 class="write-title" v-html="$global.formatstr(doc.s1.h1, month)"></h1>

			<div class="write-by">{{ $global.formatTimestamp(new Date()) }} By Hankson</div>

			<p class="writter-content">
				OBWhatsApp is a modified version of the popular messaging app, W/App. It offers a plethora of unique features that are widely appreciated by users. One of its notable features is the ability to conceal the "read" and "received" message indicators, which can be activated through the settings menu. <br /><br />
				Additionally, OBWhatsApp has a convenient recording feature that allows users to start recording with just one click on the recording icon. This app also has advanced audio recording features, enabling users to listen to multiple recordings without having to play each one individually. <br /><br />To understand this app more deeply, you will need to read the complete article. and you can also download the latest version of this app from here with the simple and easy download guide.
			</p>

			<img src="@/assets/ob-new.png" alt="" class="writer-banner" />

			<DownloadBtn class="fix has-exp" v-show="showFixBtn" :exp-data="`show_${pageName}home_download`" :name="pageName" :from="from" :filename="filename" :isOffical="isOffical" />
			<ApkDetail :doc="doc" />

			<DownloadBtn class="top-download has-exp" :exp-data="`show_${pageName}home_download`" :name="pageName" :from="from" :filename="filename" :isOffical="isOffical" />

			<img src="@/assets/gb/p-1-low.png" :src-data="doc.s1.adimg_1" alt="phone" class="phone loadimg" />

			<p class="intro-item intro-item-1" v-html="doc.s1.intro_1"></p>

			<h2 class="intro-title intro-title-1"><span @click="gotochat()" class="cursor-point">OBWhatsApp</span> vs WhatsApp - ما الفرق؟</h2>

			<p class="intro-item intro-item-2" v-html="doc.s1.intro_2"></p>

			<table id="playlist" cellspacing="0">
				<tbody>
					<tr>
						<th style="width:29%">ميزة</th>
						<th>OBWhatsApp</th>
						<th>WhatsApp</th>
					</tr>
					<tr v-for="(data, i) in feature" :key="i">
						<td>
							<div v-html="data.feature"></div>
						</td>
						<td>
							<div v-html="data.gb"></div>
						</td>
						<td>
							<div v-html="data.whs"></div>
						</td>
					</tr>
				</tbody>
			</table>
		</section>

		<S2 :doc="doc" />

		<S3 :doc="doc" :pageName="pageName" />

		<div id="snippet-box">
			<div class="snippet-title">Summary</div>

			<div itemscope="" itemtype="https://schema.org/SoftwareApplication">
				<div class="aio-info">
					<div class="snippet-label-img snippet-clear">Author Rating</div>
					<div class="snippet-data-img"><span class="star-img"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"></span></div>
					<div class="snippet-clear"></div>
					<div class="aggregate_sec" itemprop="aggregateRating" itemscope="" itemtype="https://schema.org/AggregateRating">
						<div class="snippet-label-img">Aggregate Rating</div>
						<div class="snippet-data-img"><span itemprop="ratingValue">3.1</span> based on <span class="rating-count" itemprop="reviewCount">45492</span> votes </div>
					</div>
					<div class="snippet-clear"></div>
					<div class="snippet-label-img">Software Name</div>
					<div class="snippet-data-img"> <span itemprop="name">OBWhatsApp APK</span></div>
					<div class="snippet-clear"></div>
					<div class="snippet-label-img">Operating System</div>
					<div class="snippet-data-img"> <span itemprop="operatingSystem">Android</span></div>
					<div class="snippet-clear"></div>
					<div class="snippet-label-img">Software Category</div>
					<div class="snippet-data-img"> <span itemprop="applicationCategory">App</span></div>
					<div class="snippet-clear"></div>
					<div class="snippet-label-img">Price</div>
					<div class="snippet-data-img"> <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
							<span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span></div>
					<div class="snippet-clear"></div>
					<div class="snippet-label-img">Landing Page</div>
					<div class="snippet-data-img"> <a itemprop="featureList" href="https://www.obwhatsappdownload.com/">https://www.obwhatsappdownload.com/</a></div>
					<div class="snippet-clear"></div>
				</div>
			</div>
		</div>

		<Rate />


	</div>
</template>

<script>
import "@/css/gb/pc.scss";
import "@/css/gb/mobile.scss";
import DownloadBtn from "@/components/mobile/DownloadBtnNew.vue";
import S2 from './assets/section2.vue'
import S3 from './assets/section3.vue'
import ApkDetail from './assets/apkdetail.vue'
import Rate from "@/components/rate.vue";


export default {
	name: 'mobile',
	components: {
		DownloadBtn,
		S2,
		S3,
		ApkDetail,
		Rate
	},
	data() {
		return {
			pageName: 'ob',
			doc: null,
			myApp: null,
			from: 'ob',
			filename: 'ob',
			showFixBtn: true,

			feature: [
				{
					feature: 'القرآن',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'إخفاء حالة الاتصال بالإنترنت',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'وضع الطيران',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'تنزيل الحالة',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'أضف خطوطًا / ملصقات مخصصة',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'تقاسم وسائل الاعلام',
					gb: 'Maximum 200MB',
					whs: 'Maximum 15MB',
				},
				{
					feature: 'طول حرف الحالة',
					gb: 'Maximum 255',
					whs: 'Maximum 139',
				},
				{
					feature: 'الموضوعات المدعومة',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'وضع DND',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'تجميد آخر ظهور',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'تعطيل العلامة المعاد توجيهها',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'تعطيل / تخصيص الاتصال',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'حالة مكافحة الحذف / الرسائل',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'قفل الأمان',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'التخصيص بالكامل',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'رد آلي',
					gb: '<div class="check-1"></div>',
					whs: '<div class="check-0"></div>',
				},
				{
					feature: 'زيادة الحد الامامي',
					gb: 'Maximum 250',
					whs: 'Maximum 10',
				},
				{
					feature: 'زيادة حد مشاركة الصور',
					gb: 'Maximum 100',
					whs: 'Maximum 30',
				},
			],

			month: '',
		
			isOffical: 0,
			ratingStar: null,
			showCollect: false,

		}
	},
	created() {
		this.myApp = this.$store.state.ob
		this.doc = this.myApp.doc
	},
	beforeMount() {
		[this.from, this.filename, this.isOffical] = [
			this.$global.GetQueryString("from") || "",
			this.$global.GetQueryString("filename") || "",
			parseInt(this.$global.GetQueryString("isOffical")) || 0,
		];

		this.$store.state.activeName = this.myApp.name;
		this.$store.state.activeLogo = this.myApp.logo;

		const monthArray = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		this.month = monthArray[new Date().getMonth()];

		console.log(`show_obhome_${this.$route.name}`);

		this.$logEvent(`show_obhome_${this.$route.name}`);
	},
	mounted() {
		console.log('mounted');
		this.$global.img_loading();

		setTimeout(() => {
			this.viewHeight = window.innerHeight;
			this.scrollEvent();
			// 监听scroll事件
			this.scrollEventFun = this.$global.throttle(this.scrollEvent, 300);
			window.addEventListener("scroll", this.scrollEventFun, false);

			if (this.$route.params.scrollTo) {
				document
					.querySelector(`#${this.$route.params.scrollTo}`)
					.scrollIntoView({
						behavior: "smooth",
					});
			}
		}, 1000);
		
	},
	beforeDestroy() {
		// 防止内存溢出
		window.removeEventListener('scroll', this.scrollEventFun, false)
	},
	methods: {
		
		getAPK() {
			this.$server
				.getAPPInfo({
					promotionChannel: this.from,
					promotionName: this.filename,
				})
				.then((res) => {
					console.log(res)
					if (res.code == 200) {
						this.$root.partners = res.data.partners
						this.$store.commit('ob/UPDATE_APK', res.data.apk)
						this.$store.state.activeAPK = res.data.apk
					} else {
						this.$store.state.activeAPK = process.env.VUE_APP_OBLINK
						this.$store.commit('ob/UPDATE_APK', process.env.VUE_APP_OBLINK)
					}
				})
		},
		getOfficalApk(set = 1) {
			this.$server.getOfficalApk({ domain: 'gbwhatsapp.download', appName: 'OB' }).then((res) => {
				console.log(res)
				if (res.code == 200) {
					console.log('apk getted')
					if(res.data.promotion.apk == this.myApp.apk){
						console.log('值相同 不必从新获取');
						return
					}
					document.getElementById("saving-data").innerHTML =
							JSON.stringify(res.data.promotion);
					if (set == 1) {
						this.$store.state.activeAPK = res.data.promotion.apk
						this.$store.commit('ob/UPDATE_APK', res.data.promotion.apk)
					}
					if (set == 2) {
						//取自随机的
						this.$store.state.activeAPK = res.data.apk
						this.$store.commit('ob/UPDATE_APK', res.data.apk)
					}

					console.log(this.$store.state.ob.apk)
					this.$store.commit('ob/UPDATE_RATE', res.data.promotion.score)
					this.$store.commit('ob/UPDATE_CLICKS', res.data.promotion.totalCount)
					this.$store.commit('ob/UPDATE_UPDATETIME', res.data.promotion.updateTime)
				} else {
					this.$store.state.activeAPK = process.env.VUE_APP_OBLINK
					this.$store.commit('ob/UPDATE_APK', process.env.VUE_APP_OBLINK)
				}
			})
		},
		getOffsetTop(element) {
			let actualTop = element.offsetTop
			let current = element.offsetParent
			while (current !== null) {
				actualTop += current.offsetTop
				current = current.offsetParent
			}
			return actualTop
		},
		// 获取窗口滚动高度
		scrollTop() {
			return document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
		},
		scrollEvent() {
			let $scrollTop = this.scrollTop()
			if ($scrollTop >= 200) {
				document.getElementById('head').classList.add('fix_top')
			}
			if ($scrollTop >= 20) {
				this.showTeleMsg = false
			}
			// if ($scrollTop >= 2800) {
			// 	this.showFixBtn = true
			// }
			// if ($scrollTop < 2800) {
			// 	this.showFixBtn = false
			// }
			if ($scrollTop < 200) {
				document.getElementById('head').classList.remove('fix_top')
			}
			let nodeArr = document.querySelectorAll('.has-exp')
			for (let i = 0; i < nodeArr.length; i++) {
				let domTop = this.getOffsetTop(nodeArr[i]) + nodeArr[i].getBoundingClientRect().height / 3
				// 当正文部分露出即发此曝光
				let topHeight = this.getOffsetTop(nodeArr[i]) - $scrollTop
				// console.log('topHeight', topHeight);
				let bottomHeight = domTop - $scrollTop
				if (topHeight >= 0 && bottomHeight <= this.viewHeight) {
					if (!nodeArr[i].getAttribute('data-exposure')) {
						nodeArr[i].setAttribute('data-exposure', '1')
						const _this = this
						new Promise((resolve) => {
							resolve()
						}).then(() => {
							let expData = nodeArr[i].getAttribute('exp-data')
							_this.expPost(expData)
						})
					}
				}
				if (topHeight > this.viewHeight) return false
			}
		},
		expPost(exposeData) {
			this.$logEvent(exposeData)
			console.log(`曝光统计log ${exposeData}`)
		},
		gotoTele() {
			window.location.href = 'https://t.me/obwhatsapp2023'
		},
		gotochat() {
			window.location.href = 'https://www.whatsplus.online/'
		},
	},
	metaInfo() {
		return {
			meta: [
				{
					name: "viewport",
					content:
						"width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0",
				},
			],
		};
	},
}
</script>
