<template>
	<section class="rate-container">
		<div class="download-rating-coantiner">
			<div class="rating-title"><img src="@/assets/like.png" alt="" /> Please rate us</div>

			<div class="rating-control-row">
				<div class="star-item" :class="{ active: i <= ratingStar }" @click="rate(i)" v-for="i in 5" :key="i"></div>
			</div>
		</div>

		<div class="collect-me-container" v-if="showCollect" @click="showCollect = false">
			<div class="collect-me-row">
				<img src="@/assets/close-icon.png" class="collect-close" @click="showCollect = false" alt="" />
				<h4>Thank you for your feedback</h4>
				<img class="collect-pic" src="@/assets/gb/collect.png" alt="" />
				<p>It is highly recommended to bookmark our website or join our official channel to timely obtain the latest version updates to avoid product expiration</p>
			</div>
		</div>

	</section>
</template>

<script>
export default {
	name: "rate",
	data() {
		return {
			ratingStar: null,
			showCollect: false,
		};
	},
	methods: {
		rate(i) {
			if (this.$store.state.voteScore) {
				return;
			}
			console.log(i);
			this.ratingStar = i;
			this.rateScore(i);
		},
		rateScore(score) {
			// this.$server.erate({ rate: score }).then((res) => {
			// 	console.log(res);
			// 	this.$store.commit("UPDATE_VOTING", score);
			// 	this.showCollect = true;
			// 	setTimeout(() => {
			// 		this.showCollect = false;
			// 	}, 8000);
			// });
			this.$store.commit("UPDATE_VOTING", score);
			this.showCollect = true;
			setTimeout(() => {
				this.showCollect = false;
			}, 8000);
		},
	},
};
</script>
