/*
 * @Author: xin.song
 * @Date: 2020-03-12 17:09:05
 * @Last Modified by: xin.song
 * @Last Modified time: 2023-07-17 14:59:44
 */

import { service } from './service.js'

const server = {
	getAPK(req) {
		return service.get(process.env.VUE_APP_GETAPP_API + '/promote', req)
	},

	getAppList(req) {
		return service.post(`${process.env.VUE_APP_GATEWAPP_API}/external/content/40201/api`, req)
	},
	getAPPInfo(req) {
		return service.post(`${process.env.VUE_APP_H5_API}/official`, req)
	},
	getOfficalApk(req) {
		return service.post(`${process.env.VUE_APP_H5_API}/apk`, req)
	},
	getappScore(req) {
		return service.get(`${process.env.VUE_APP_FEEDBACK_API}/as/q`, req)
	},
	saveappScore(req) {
		return service.post(`${process.env.VUE_APP_FEEDBACK_API}/as/s`, req)
	},
	eget(req) {
		return service.get('/express/eget', req)
	},
	epost(req) {
		return service.post('/express/epost', req)
	},
	erate(req) {
		return service.post('/express/rate', req)
	},
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
	if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera

		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return 'Windows Phone'
		}

		if (/android/i.test(userAgent)) {
			return 'Android'
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return 'iOS'
		}
	}

	return null
}

function formatstr(string) {
	// this.formatstr('And the %1 want to know whose %2', 'papers', 'toli')
	var args = arguments
	var pattern = new RegExp('%([1-' + arguments.length + '])', 'g')
	return String(string).replace(pattern, function(match, index, position, all) {
		// console.log(match + "&" + index + "&" + position + "&" + all);
		return args[index]
	})
}

function download(apk, name) {
	// // 创建隐藏的可下载链接
	if (apk.includes('www.gbwhatsapp.download')) {
		apk = apk.replace('www.gbwhatsapp.download', 'cdn.gbwhatsapp.download')
	}

	var eleLink = document.createElement('a')
	eleLink.setAttribute('href', apk)

	eleLink.style.display = 'none'
	// 触发点击
	document.body.appendChild(eleLink)
	eleLink.click()
	// 然后移除
	document.body.removeChild(eleLink)
}

function GetQueryString(str) {
	if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
		var reg = new RegExp('(^|&)' + str + '=([^&]*)(&|$)', 'i')
		var r = window.location.search.substr(1).match(reg)
		if (r != null) return unescape(r[2])
	}

	return null
}

function formatNum(num) {
	if (num) {
		return num.toString().replace(/\B(?=(?:\d{3})+\b)/g, ',')
	} else {
		return 0
	}
}

function formatDate(oldDate, fmt) {
	// oldDate is 13 digits
	// fmt for formatting, e.g. yyyy-MM-dd hh:mm
	let date = new Date()
	if (typeof oldDate === 'string' || typeof oldDate === 'number') {
		date = new Date(+oldDate)
	} else {
		date = oldDate
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}

	const o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds(),
	}

	const padLeftZero = (str) => ('00' + str).substr(str.length)

	Object.keys(o).forEach((k) => {
		if (new RegExp(`(${k})`).test(fmt)) {
			const str = o[k] + ''
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
		}
	})

	return fmt
}

function day(sDate1, sDate2) {
	let sDate3 = new Date(sDate2).getTime() - new Date(sDate1).getTime() //時間差的毫秒數
	if (sDate3 < 0) {
		return 0
	}
	//計算出相差天數
	let iDays = Math.floor(sDate3 / (24 * 3600 * 1000))
	return iDays
}
function isJson(obj) {
	return typeof obj == 'object' && Object.prototype.toString.call(obj).toLowerCase() === '[object object]' && !Object.keys(obj).length
}

function isJsonString(str) {
	if (typeof str == 'string') {
		try {
			return JSON.parse(str) && typeof JSON.parse(str) === 'object'
		} catch (e) {
			console.log('error：' + str + '!!!' + e)
			return false
		}
	}
	return false
}

function img_loading() {
	//初始化需要显示的图片，并且指定显示的位置
	var imglist = document.getElementsByClassName('loadimg')
	// console.log(imglist);
	for (var i = 0; i < imglist.length; i++) {
		let tt = imglist[i]
		// console.log(tt);
		// console.log(tt.loading);
		//防止重复加载
		if (tt.loading == true) {
			continue
		}
		//没有该属性代表不加载
		if (!tt.getAttribute('src-data')) {
			continue
		}
		tt.loading = true
		Imagess(tt.getAttribute('src-error') ? tt.getAttribute('src-error') : tt.src, tt.getAttribute('src-data'), (obj) => {
			tt.src = obj.src
			tt.removeAttribute('src-data')
		})
	}
}

function Imagess(errorimg, url, callback, error) {
	//判断是否加载完成
	var val = url
	var img = new Image()
	img.onload = () => {
		// window.hide();
		if (img.complete == true) {
			callback(img)
		}
	}
	//如果因为网络或图片的原因发生异常，则显示该图片
	if (error) {
		img.onerror = error
	} else {
		img.onerror = function() {
			img.src = errorimg
		}
	}
	img.src = val
}

function debounce(fn, wait) {
	//防抖
	var timeout = null
	return function() {
		if (timeout !== null) clearTimeout(timeout)
		timeout = setTimeout(fn, wait)
	}
}
function throttle(fun, threshhold = 160) {
	// time 必然触发执行的时间间隔
	let timeout
	let startTime = new Date()
	return function() {
		let context = this
		let args = arguments
		let curTime = new Date() - 0

		clearTimeout(timeout)

		if (curTime - startTime >= threshhold) {
			fun.apply(context, args)
			startTime = curTime
		} else {
			timeout = setTimeout(function() {
				fun.apply(context, args)
			}, threshhold)
		}
	}
}
function scrollTop() {
	// 获取窗口滚动高度
	return document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
}
function getOffsetTop(element) {
	let actualTop = element.offsetTop
	let current = element.offsetParent
	while (current !== null) {
		actualTop += current.offsetTop
		current = current.offsetParent
	}
	return actualTop
}

function kFormatter(num) {
	return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(0) + 'k' : Math.sign(num) * Math.abs(num)
}

function formatTimestamp(timestamp) {
	var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
	var date = new Date(timestamp)
	var month = months[date.getMonth()]
	var day = date.getDate()
	var year = date.getFullYear()
	return month + ' ' + day + ',' + year
}

function gotoURL(url) {
	window.location.href = url
}

function isMobileDevice() {
	if (typeof navigator !== 'undefined') {
		// 在浏览器环境中执行的代码
		// 使用 navigator 对象进行相关操作
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
	} else {
		// 在服务器环境中执行的代码
		// 忽略或提供替代方案
		return false
	}
}
// 暴露出这些属性和方法
export default {
	GetQueryString,
	formatNum,
	getMobileOperatingSystem,
	formatDate,
	isJson,
	isJsonString,
	day,
	img_loading,
	Imagess,
	download,
	formatstr,
	server,
	formatTimestamp,
	debounce,
	throttle,
	kFormatter,
	scrollTop,
	getOffsetTop,
	gotoURL,
	isMobileDevice
}
