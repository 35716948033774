<template>
	<div id="head" class="head">
		<div class="container-left-ul">
			<div class="hamburger" id="bars" @click="dropFunc()">
				<span class="bar"></span>
				<span class="bar"></span>
				<span class="bar"></span>
			</div>
		</div>

		<!-- 下拉菜单 -->
		<transition name="dropdown-fade-show">
			<div v-show="dropDownShow" @touchmove.prevent class="dropdown" id="dropdown">
				<div class="drop-down-head">
					<div class="logo">
						<img :src="appLogo" class="head-img" alt="logo" />
					</div>
					<div class="close-icon" @click="dropFunc()"></div>
				</div>

				<div class="drop-down-click">
					<!-- <div class="product-list">
						<div class="drop-down-list-title">Products</div>
						<div class="product-item" @click="goto(data.url)" v-for="(data, i) in productList" :key="i">
							{{ data.name }}
						</div>
					</div>

					<div class="product-list">
						<div class="drop-down-list-title">Blog</div>
						<div class="product-item" @click="goto('/blogs')">
							Blogs
						</div>
					</div> -->
					
					<div class="product-list">
						<div class="drop-down-list-title">Android Apps</div>
						<div class="product-item" @click="scroll(data.url)" v-for="(data, i) in funcList" :key="i">
							{{ data.name }}
						</div>
					</div>

				


					<!-- <div class="product-list">
						<div class="drop-down-list-title">About</div>
						<div class="product-item" @click="goto('/dmca')">
							DMCA
						</div>
					</div> -->

				
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export default {
	name: 'Tophead',
	data() {
		return {
			dropDownShow: false,
			from: null,
			productList: [
				{ name: 'GBWhatsApp', url: '/' },
				{ name: 'YoWhatsApp', url: '/yowhatsapp.html' },
				{ name: 'OBWhatsApp', url: '/obwhatsapp.html' },
				{ name: 'WhatsApp Plus', url: '/whatsapp2plus.html' },
				{ name: 'GBWhatsApp IOS', url: '/ios/gbWhatsAppIos-apk.html' },
			],
			funcList: [
				{ name: 'App', url: 'section-3' },
				{ name: 'Games', url: 'section-3' },
			],
		}
	},
	props: {
		appName: String,
		appLogo: String,
	},
	methods: {
		goto(link) {
			window.location.href = link
		},
		scroll(to) {
			const targetElement = document.querySelector('#dropdown')
			enableBodyScroll(targetElement)
			if (this.$route.name == 'download_m') {
				this.$router.push({
					name: this.$store.state.lastPage,
					params: { scrollTo: to },
				})
				return
			}
			document.querySelector(`#${to}`).scrollIntoView({
				behavior: 'smooth',
			})
			this.dropDownShow = false
		},
		dropFunc() {
			this.dropDownShow = !this.dropDownShow
			const targetElement = document.querySelector('#dropdown')
			if (this.dropDownShow) {
				disableBodyScroll(targetElement)
			}
			if (!this.dropDownShow) {
				enableBodyScroll(targetElement)
			}
		},
	},
}
</script>
