import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import createRouterM from './router/'
import createStore from './store'
// 把 Vue Router 当前的 $route 同步为 Vuex 状态的一部分
import { sync } from 'vuex-router-sync'
import global from '@/utlis/global'
import UAParser from 'ua-parser-js'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'
import { getMessaging, getToken } from 'firebase/messaging' //TODO:

const firebaseConfig = {
	apiKey: 'AIzaSyDyxNuGKvctIzS4n5WV7qpK9sgERe009lw',
	authDomain: 'obwhatsappdownload-com-a2ca1.firebaseapp.com',
	projectId: 'obwhatsappdownload-com-a2ca1',
	storageBucket: 'obwhatsappdownload-com-a2ca1.appspot.com',
	messagingSenderId: '132349189619',
	appId: '1:132349189619:web:dadfb26eb2b16370aac686',
	measurementId: 'G-CY18M3575M',
}
// Initialize Firebase
let apps = null,
	analytics = null,
	messaging = null //TODO:

const uaParser = new UAParser()
const deviceInfo = uaParser.getResult()

const isMobileDevice = deviceInfo.device.type === 'mobile'

// console.log(deviceInfo, isMobileDevice)
isSupported().then((result) => {
	//TODO:
	if (result) {
		apps = initializeApp(firebaseConfig)
		analytics = getAnalytics(apps)
		logEvent(analytics, 'in_page_gb')
	}
})

// simple event

Vue.prototype.$global = global
Vue.prototype.$server = global.server
Vue.prototype.$ua = {
	deviceInfo,
	isMobileDevice,
}
Vue.prototype.$logEvent = (event, params = {}) => {
	// console.log(event)
	isSupported().then((result) => {
		if (result) {
			logEvent(analytics, event, params)
		}
	})
}

export default function createApp() {
	const router = createRouter()
	const store = createStore()
	// 同步路由状态(route state)到 store
	sync(store, router)
	const app = new Vue({
		router,
		store,
		data() {
			return {
				initData: null,
				isMobileDevice: isMobileDevice,
			}
		},
		mounted() {
			this.initData = initData
			this.initializeFirebase() //TODO: Firebase 初始化和其它客户端特定的初始化可以放在这里
		},
		methods: {
			//TODO:
			async initializeFirebase() {
				if (typeof window !== 'undefined' && (await isSupported())) {
					messaging = getMessaging(apps)
					this.requestNotificationPermission() // 请求通知权限
				}
			},
			requestNotificationPermission() {
				if (typeof window !== 'undefined' && 'Notification' in window) {
					// 此处确保代码只在客户端执行
					Notification.requestPermission().then((result) => {
						if (result === 'denied') {
							console.log('拒绝显示系统通知')
							this.$logEvent('game_outside_push_denied')
							return
						}
						if (result === 'default') {
							console.log('默认')
							return
						}
						console.log('允许显示系统通知')
						this.$logEvent('game_outside_push_permit')
						this.subscribeToPushNotifications()
					})
				} else {
					console.log('此环境不支持通知。')
				}
			},
			subscribeToPushNotifications() {
				console.log(messaging)
				getToken(messaging, { vapidKey: 'BMLIoaCOj_GyMA4sC48ebIl4W-X5ZGNGbn1lQi1DivGqEXrDFqgvTo4i1p1ZJ2XeLXtG6FBPvMF0s1YJLjisS2I' })
					.then((currentToken) => {
						if (currentToken) {
							console.log('设备令牌', currentToken)
							this.$logEvent('game_outside_success_init_user_token')
							this.sendPushNotification(currentToken)
						} else {
							console.log('无法获取设备令牌。')
						}
					})
					.catch((err) => {
						console.log('获取设备令牌时出错。', err)
					})
			},
			sendPushNotification(token) {
				const notification = {
					to: token,
					notification: {
						body: '新消息',
					},
				}
				console.log(notification)
			},
		},
		render: (h) => h(App),
	})
	return {
		app,
		router,
		store,
	}
}
